<!-- eslint-disable vue/html-closing-bracket-newline -->
<template>
  <div>
    <div class="row">
      <div class="col-md-9">
        <div class="row">
          <div class="col-12 ">
            <b-card title="Feedback List">
              <div class="d-flex justify-content-end">
                <BFormInput
                    class="mb-2" type="search" placeholder="Search..."
                    style="width:50%"
                />
              </div>
              <b-card-text>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                    <tr>
                      <th class="text-center">Sl</th>
                      <th class="text-center">Name</th>
                      <th class="text-center">Title</th>
                      <th class="text-center">Feedback</th>
                      <th class="text-center">image</th>
                      <th class="text-center">rating</th>
                      <th class="text-center">Status</th>
                      <th class="text-center">Action</th>
                    </tr>
                    </thead>
                    <tbody class="text-center" v-if="feedbacks">
                    <tr
                        v-for="(feedback, index) in feedbacks"
                        :key="index"
                    >
                      <td class="align-middle text-center">
                        {{ index + 1 }}
                      </td>
                      <td>
                        <span>{{
                            feedback.name
                          }}</span>
                      </td>
                      <td>
                        <span>{{
                            feedback.title
                          }}</span>
                      </td>
                      <td>
                        <span>{{
                            feedback.feedback
                          }}</span>
                      </td>
                      <td>
                        <img
                            style="border-radius: 20px"
                            width="60"
                            :src="$store.state.base_url + feedback.image"
                        >
                      </td>
                      <td>
                        <span>{{
                            feedback.rating
                          }}</span>
                      </td>
                      <td class="align-middle text-center">
                        <span
                            class="badge"
                            :class="
                            feedback.status == 1 ? 'badge-success' : 'badge-danger'
                          "
                        >
                          {{ feedback.status == 1 ? "Active" : "Inactive" }}
                        </span>
                      </td>
                      <td class="align-middle text-center">
                        <div class="d-flex">
                          <div class="mr-1">
                            <button
                                class="btn btn-primary btn-sm"
                                @click="edit(feedback.id)"
                            >
                              Edit
                            </button>
                          </div>
                          <div>
                            <button
                                class="btn btn-danger btn-sm"
                                @click="destroy(feedback.id)"
                            >
                              Delete
                            </button>
                          </div>


                        </div>
                      </td>

                    </tr>
                    </tbody>
                  </table>

                </div>
              </b-card-text>
            </b-card>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <b-card :title="(!form.id ? 'Create' : 'Update')">
          <b-card-text
          >
            <validation-observer ref="loginValidation">
              <b-form
                  class="auth-login-form mt-2"
                  @submit.prevent
                  enctype="multipart/form-data"
              >
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group label="Name" label-for="name">
                      <validation-provider
                          #default="{ errors }"
                          name="name"
                          rules="required"
                      >
                        <b-form-input
                            id="name"
                            v-model="form.name"
                            :state="errors.length > 0 ? false : null"
                            name="name"
                            placeholder="Enter The Student Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group label="Title" label-for="title">
                      <validation-provider
                          #default="{ errors }"
                          name="title"
                          rules="required"
                      >
                        <b-form-input
                            id="title"
                            v-model="form.title"
                            :state="errors.length > 0 ? false : null"
                            name="title"
                            placeholder="Enter Title"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group label="Feedback" label-for="feedback">
                      <validation-provider
                          #default="{ errors }"
                          name="feedback"
                          rules="required"
                      >
                        <b-form-textarea
                            type="textarea"
                            id="feedback"
                            v-model="form.feedback"
                            :state="errors.length > 0 ? false : null"
                            name="feedback"
                            placeholder="Give Feedback"
                            :autosize="{ minRows: 4, maxRows: 4 }"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group label="Rating" label-for="rating">
                      <validation-provider
                          #default="{ errors }"
                          name="rating"
                          rules="required"
                      >
                        <b-form-input
                            type="number"
                            id="rating"
                            v-model="form.rating"
                            :state="errors.length > 0 ? false : null"
                            name="rating"
                            placeholder="Type Rating"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <div class="col-md-12">
                    <div class="border rounded p-2 mb-2">
                      <b-media
                          no-body
                          vertical-align="center"
                          class="flex-column flex-md-row"
                      >
                        <b-media-aside>
                          <b-img
                              :src="form.student_image"
                              height="50"
                              width="50"
                              class="rounded mr-2 mb-1 mb-md-0"
                          />
                        </b-media-aside>
                        <b-media-body>
                          <b-form-file
                              id="image"
                              accept=".jpg, .png, .gif"
                              @change="loadImage($event)"
                              placeholder="Select a Image"
                              drop-placeholder="Drop file here..."
                          />
                        </b-media-body>
                      </b-media>
                    </div>
                  </div>
                </div>
                <!-- submit buttons -->
                <b-form-checkbox
                    name="status"
                    style="margin-top: 5px"
                    v-model="form.status"
                    switch
                    inline
                >
                </b-form-checkbox>
                <b-button v-if="!form.id"
                          type="submit"
                          class="float-right"
                          variant="primary"
                          @click="validationForm"
                >
                  Create
                </b-button>
                <b-button v-if="form.id"
                          type="submit"
                          class="float-right"
                          variant="primary"
                          @click="update()"
                >
                  Update
                </b-button>
                <b-button
                    type="submit"
                    class="float-right mx-1"
                    variant="outline-primary"
                    @click="Close"
                >
                  Close
                </b-button>
              </b-form>
            </validation-observer>
          </b-card-text
          >
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import {required} from "@validations";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import vSelect from "vue-select";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import {quillEditor} from "vue-quill-editor";

export default {
  data() {
    return {
      form: {
        id: null,
        name: null,
        title: null,
        feedback:null,
        rating: null,
        student_image: null,
        status: true,
      },
      feedbacks: [],
      image: null
    };
  },
  mounted() {
    this.getResults();
  },
  methods: {
    async getResults() {
      const feedbacks = await this.callApi(
          "get",
          "/app/cms/student/feedback",
      );
      if (feedbacks.status) {
        this.feedbacks = feedbacks?.data.student_feedback;
      }
    },

    Close() {
      this.form.id = "";
      this.form.name = "";
      this.form.title = "";
      this.form.feedback = "";
      this.form.student_image = "";
      this.image = "";
      this.form.rating = "";
      this.form.status = true;
    },


    loadImage(e) {
      this.image = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.form.student_image = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    async edit(id) {
      const feedbacks = await this.callApi(
          "get",
          "/app/cms/student/feedback/" + id
      );

      if (feedbacks.data) {
        this.form.id = feedbacks?.data.student_feedback.id;
        this.form.name = feedbacks?.data.student_feedback.name;
        this.form.title = feedbacks?.data.student_feedback.title;
        this.form.feedback = feedbacks?.data.student_feedback.feedback;
        this.form.student_image = this.$store.state.base_url + feedbacks?.data.student_feedback.image;
        this.form.rating = feedbacks?.data.student_feedback.rating;
        this.form.status = feedbacks?.data.student_feedback.status == 1 ? true : false;
      }
    },

    async update() {
      const fd = new FormData();
      if (this.image) {
        fd.append(
            "image",
            this.image,
            this.image.name
        );
      }
      fd.append("name", this.form.name);
      fd.append("title", this.form.title);
      fd.append("feedback", this.form.feedback);
      fd.append("rating", this.form.rating);
      fd.append("status", this.form.status);

      axios
          .post("/app/cms/student/feedback/" + this.form.id, fd)
          .then((res) => {
            this.getResults();
            this.Close();
            this.s(res.data.message);
          })
          .catch((e) => {
            if (e.response.status === 422) {
              if (e.response.data.errors.name)
                this.e(e.response.data.errors.name[0]);
              if (e.response.data.errors.title)
                this.e(e.response.data.errors.title[0]);
              if (e.response.data.errors.feedback)
                this.e(e.response.data.errors.feedback[0]);
              if (e.response.data.errors.image)
                this.e(e.response.data.errors.image[0]);
              if (e.response.data.errors.rating)
                this.e(e.response.data.errors.rating[0]);
              if (e.response.data.errors.status)
                this.e(e.response.data.errors.status[0]);
            }
          });

    },

    async create() {
      const fd = new FormData();
      if (this.image) {
        fd.append(
            "image",
            this.image,
            this.image.name
        );
      }
      fd.append("name", this.form.name);
      fd.append("title", this.form.title);
      fd.append("feedback", this.form.feedback);
      fd.append("rating", this.form.rating);
      fd.append("status", this.form.status);

      axios
          .post("/app/cms/student/feedback", fd)
          .then((res) => {
            this.getResults();
            this.Close();
            this.s(res.data.message);
          })
          .catch((e) => {
            if (e.response.status === 422) {
              if (e.response.data.errors.name)
                this.e(e.response.data.errors.name[0]);
              if (e.response.data.errors.title)
                this.e(e.response.data.errors.title[0]);
              if (e.response.data.errors.feedback)
                this.e(e.response.data.errors.feedback[0]);
              if (e.response.data.errors.image)
                this.e(e.response.data.errors.image[0]);
              if (e.response.data.errors.rating)
                this.e(e.response.data.errors.rating[0]);
              if (e.response.data.errors.status)
                this.e(e.response.data.errors.status[0]);
            }
          });
    },

    async destroy(id) {
      const feedbacks = await this.callApi(
          "delete",
          "/app/cms/student/feedback/" + id
      )
    .catch((e) => {
        if (e.response.status === 422) {
          if (e.response.data.errors.name)
            this.e(e.response.data.errors.name[0]);
          if (e.response.data.errors.title)
            this.e(e.response.data.errors.title[0]);
          if (e.response.data.errors.feedback)
            this.e(e.response.data.errors.feedback[0]);
          if (e.response.data.errors.image)
            this.e(e.response.data.errors.image[0]);
          if (e.response.data.errors.rating)
            this.e(e.response.data.errors.rating[0]);
          if (e.response.data.errors.status)
            this.e(e.response.data.errors.status[0]);
        }
      });
      this.getResults();
    },


    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.create();
        }
      });
    },
  },
  components: {
    quillEditor,
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
  },
  computed: {},
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>