<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <div>
      <validation-observer ref="loginValidation">
        <b-card title="Student Feedback Page">
        <b-form
          class="auth-login-form mt-2"
          @submit.prevent
          enctype="multipart/form-data"
        >
          <div class="row">
            <!-- $store.state.base_url + 'images/CIT-13-Years.jpg' -->
            <div class="col-md-6">
                      <b-form-group label="Title" label-for="title">
                      <validation-provider
                        #default="{ errors }"
                        name="title"
                        rules="required"
                      >
                        <b-form-input
                          id="title"
                          v-model="form.title"
                          :state="errors.length > 0 ? false : null"
                          name="title"
                          placeholder="Enter Student Feedback Title"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
            </div>
            <div class="col-md-6">
                            <b-form-group label="Meta Title" label-for="meta_title">
                      <validation-provider
                        #default="{ errors }"
                        name="meta_title"
                        rules="required"
                      >
                        <b-form-input
                          id="meta_title"
                          v-model="form.meta_title"
                          :state="errors.length > 0 ? false : null"
                          name="meta_title"
                          placeholder="Enter Student Feedback Meta Title"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
            </div>
            <div class="col-md-6">
                  <b-form-group label="Description" label-for="description">
                      <validation-provider
                        #default="{ errors }"
                        name="description"
                        rules="required"
                      >
                        <Input
                        type="textarea"
                          id="description"
                          v-model="form.description"
                          :state="errors.length > 0 ? false : null"
                          name="description"
                          placeholder="Enter Description"
                          :autosize="{ minRows: 4, maxRows: 4 }"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
                  <b-form-group label="Meta Description" label-for="meta_description">
                      <validation-provider
                        #default="{ errors }"
                        name="meta_description"
                        rules="required"
                      >
                        <Input
                        type="textarea"
                          id="meta_description"
                          v-model="form.meta_description"
                          :state="errors.length > 0 ? false : null"
                          name="meta_description"
                          placeholder="Enter Meta Description"
                          :autosize="{ minRows: 4, maxRows: 4 }"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Sub Title" label-for="sub_title">
                <validation-provider
                    #default="{ errors }"
                    name="sub_title"
                    rules="required"
                >
                  <b-form-input
                      id="sub_title"
                      v-model="form.sub_title"
                      :state="errors.length > 0 ? false : null"
                      name="sub_title"
                      placeholder="Enter Student Feedback Sub Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Status" label-for="status">
                <validation-provider
                    #default="{ errors }"
                    name="status"
                    rules="required"
                >

                  <b-form-checkbox
                      name="status"
                      style="margin-top: 5px"
                      v-model="form.status"
                      switch
                      inline
                  >
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>

          </div>
          <!-- submit buttons -->
          <b-button type="submit" variant="primary" @click="validationForm">
            Update
          </b-button>
        </b-form>
     </b-card>
      </validation-observer>
    </div>
  </template>
  <script>
  import {
    BFormFile,
    BMediaBody,
    BMediaAside,
    BMedia,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCard,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    vSelect,
  } from "bootstrap-vue";
  import { required, email } from "@core/utils/validations/validations";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  export default {
    data() {
      return {
        form: {
          title: "",
          sub_title: null,
          description: "",
          meta_title:"",
          meta_description:"",
          status:true
        },
      };
    },
    mounted() {
      this.getResults();
    },
    methods: {
      async getResults() {
        const feedbacks = await this.callApi(
            "get",
            "/app/cms/student/feedback/page",
        );
        if (feedbacks.status) {
          this.form.title = feedbacks?.data.student_feedback.title;
          this.form.sub_title = feedbacks?.data.student_feedback.sub_title;
          this.form.description = feedbacks?.data.student_feedback.description;
          this.form.meta_title = feedbacks?.data.student_feedback.meta_title;
          this.form.meta_description = feedbacks?.data.student_feedback.meta_description;
          this.form.status = feedbacks?.data.student_feedback.status == 1 ? true : false;
        }
      },

      Update() {
        axios
            .put("/app/cms/student/feedback/page", this.form)
            .then((res) => {
              this.getResults();
              this.s(res.data.message);
            })
            .catch((e) => {
              if (e.response.status === 422) {
                if (e.response.data.errors.title)
                  this.e(e.response.data.errors.title[0]);
                if (e.response.data.errors.sub_title)
                  this.e(e.response.data.errors.sub_title[0]);
                if (e.response.data.errors.description)
                  this.e(e.response.data.errors.description[0]);
                if (e.response.data.errors.meta_title)
                  this.e(e.response.data.errors.meta_title[0]);
                if (e.response.data.errors.meta_description)
                  this.e(e.response.data.errors.meta_description[0]);
                if (e.response.data.errors.status)
                  this.e(e.response.data.errors.status[0]);
              }
            });
      },
      validationForm() {
        this.$refs.loginValidation.validate().then((success) => {
          if (success) {
            this.Update();
          }
        });
      },
    },
    components: {
    BFormFile,
    BMedia,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCard,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    vSelect
},
  };
  </script>

  <style></style>
